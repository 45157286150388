import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CommandsList from "../components/commandsList"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Commands List" />

      <CommandsList></CommandsList>

      {/* <Link to="/page-2/">Go to page 2</Link> <br />
    <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
    </Layout>
  );
}

export default IndexPage
