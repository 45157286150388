import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import CommandInfo from "./commandInfo"
import SearchInput from "./searchInput"
import Fuse from 'fuse.js'
import { MDXRenderer } from "gatsby-plugin-mdx"
import PlatformSelection from "./platformSelection"
import CommandsHelp from "./commandsHelp"

import "./commandsList.css"

function CommandsList(props) {

  const data = useStaticQuery(graphql`
    query CommandsListQuery {
      commands: allCommandsJson(sort: {fields: name}) {
        nodes {
          id
          name
          editions {
            java
            bedrock
            education
          }
        }
      }
      allMdx {
        nodes {
          slug
          body
        }
      }
    }
  `)

  const items = data.commands.nodes

  const fuse = React.useMemo(
    () =>
      new Fuse(items, {
        shouldSort: true,
        tokenize: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        minMatchCharLength: 1,
        keys: [
          "name",
          // "frontmatter.description",
          // "headings.value",
        ],
      }),
    [items]
  )

  const [inputItems, setInputItems] = React.useState(items)
  const [platform, setPlatform] = React.useState('java')

  const shown = inputItems.filter(i => i.editions[platform]);

  return (
    <div>
      <SearchInput onSearch={(text) => {
        if (text) {
          setInputItems(fuse.search(text).map((node) => node.item))
        } else {
          setInputItems(items)
        }
      }}></SearchInput>

      <PlatformSelection platform={platform} onChangePlatform={(p) => {
        setPlatform(p);
      }} />

      <CommandsHelp />

      <div className="minimap hidden xl:block">
        <div className="minimap-contents">
          {shown.map(i => {
            return <div key={i.name}>
              <a href={`#${i.name}`}>{i.name}</a>
            </div>
          })}
        </div>
      </div>

      <div className="mt-8">
        {shown.map(i => {
          let markdownDescription = data.allMdx.nodes.find(n => n.slug === `${i.name}_${platform}`);
          if (!markdownDescription) {
            markdownDescription = data.allMdx.nodes.find(n => n.slug === `${i.name}_all`);
          }
          return (
            <div key={i.name}>
              <CommandInfo command={i}></CommandInfo>
              {markdownDescription ? <MDXRenderer>{markdownDescription.body}</MDXRenderer> : null}
              <hr className="my-8" />
            </div>
          );
        })}
      </div>
    </div>
  )
}

export default CommandsList
