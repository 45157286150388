import React from "react"

import IconSearch from './iconSearch'
import useEventListener from "../hooks/useEventListener"
import useFocus from "../hooks/useFocus"
import IconCross from "./iconCross"

function SearchInput(props) {
  const [text, setText] = React.useState("")
  const [focused, setFocused] = React.useState(false)
  const [focusRef, setInputFocus] = useFocus()

  const ESCAPE_KEYS = ['191', '/'];
  function handler({ key }) {
    if (ESCAPE_KEYS.includes(String(key))) {
      setInputFocus()
    }
  }

  useEventListener('keydown', handler);

  function changeText(rawText) {
    const text = rawText.replace(/^\//g, '');
    setText(text)
    props.onSearch(text)
  }

  return (
    <div className="sticky z-10 flex relative shadow-xl text-white" style={{
      top: '1em'
    }}>
      <label
        htmlFor="search"
        className="absolute inset-y-0 items-center cursor-text flex text-2xl"
        style={{
          left: '1em',
        }}
      >
        <IconSearch /><span className="sr-only">Search commands</span>
      </label>
      <input
        id="search"
        type="text"
        value={text}
        onChange={(event) => {
          changeText(event.target.value)
        }}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        placeholder={"Search commands" + ((!focused && typeof window !== 'undefined' && !window.matchMedia("(pointer: coarse)").matches) ? ' (Press / to focus)' : '')}
        autoComplete="off"
        ref={focusRef}
        className={"rounded w-full p-4 pl-16 outline-none " + (focused ? 'bg-blue-900' : 'bg-gray-800')}
      />
      {text && <div onClick={() => { changeText('') }} className="absolute inset-y-0 text-2xl items-center flex cursor-pointer" style={{ right: '1em' }}>
        <IconCross color={'white'} />
      </div>}
    </div>
  )
}

export default SearchInput
