import * as React from "react"
import Railroad from './railroad'
import RailroadSet from './railroadSet'
import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

function CommandsHelp(props) {
  function setOrToggleHelp(desired) {
    if (shownHelp === desired) {
      setShownHelp(null);
    } else {
      setShownHelp(desired);
    }
  }

  const [shownHelp, setShownHelp] = React.useState(null)

  function setOrToggleHelp(desired) {
    if (shownHelp === desired) {
      setShownHelp(null);
    } else {
      setShownHelp(desired);
    }
  }

  const diagramChoice = new Diagram(
    new Choice(
      0,
      'a',
      'b',
      'c',
    ),
  );
  const diagramOptional = new Diagram(
    new Optional(
      'maybe',
    ),
  );
  const diagramCompound = new Diagram(
    'hello',
    new NonTerminal('argument')
  );
  const diagramArgument = new ComplexDiagram(
    new Group(
      new Sequence(
        new Choice(
          0,
          'world',
          'mars',
        ),
        '!',
      ),
      'argument',
    ),
  );

  const helpTopics = [
    {
      name: 'what',
      text: 'What am I looking at?',
    },
  ];

  return (
    <div className="prose lg:prose-xl">
      <div className="flex">
        {helpTopics.map((t) => (<button key={t.name} onClick={() => { setOrToggleHelp(t.name) }}
          className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow mr-2">
          {t.text}
        </button>))}
      </div>

      {shownHelp && <h2>{helpTopics.find(t => t.name === shownHelp).text}</h2>}

      {(shownHelp === 'what') && <div className="my-16">
        <p>
          <a href="https://en.wikipedia.org/wiki/Syntax_diagram" rel="noopener" target="_blank">Railroad diagrams</a>!
          Start on the left, then choose any path to get to the right.
          Once you write everything from left to right, you have a valid command.
        </p>

        <h3>Some tips for reading</h3>

        <p>This is optional. You could include it, or you could not.</p>
        <Railroad diagram={diagramOptional} />

        <p>Here's a choice. You have to choose an option.</p>
        <Railroad diagram={diagramChoice} />

        <p>
          Gray, rounded boxes are typed exactly as you see them.
          Colored, rectangular boxes are something you need to substitute.
          On a command's page you can click the boxes to see what should go in the box.
        </p>
        <RailroadSet>
          <Railroad rep='main' interactive={true} diagram={diagramCompound} />
          <Railroad rep='argument' diagram={diagramArgument} />
        </RailroadSet>
      </div>}

      {shownHelp && <hr />}
    </div>
  )
}

export default CommandsHelp
